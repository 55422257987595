









































































































import Vue from 'vue'
import { OrderItem } from '@/types'
import { numberWithCommas } from 'vuelpers'

export default Vue.extend({
	name: 'OrderViewOrderItem',
	props: {
		showHeader: {
			default: false,
			type: Boolean as Vue.PropType<boolean>,
		},
		currencySign: {
			required: true,
			type: String as Vue.PropType<string>,
		},
		item: {
			required: true,
			type: Object as Vue.PropType<OrderItem>,
		},
	},
	computed: {
		thumbnail(): string {
			const vImage = this.item?.product?.vImage || ''
			return this.$helper.imgSrc(vImage.split(',')[0])
		},
	},
	methods: {
		numberWithCommas,
	},
})
