
















































































































































































































































































import Vue from 'vue'
import axios from 'axios'

import { mapGetters } from 'vuex'
import { numberWithCommas } from 'vuelpers'
import { Accessors } from 'vue/types/options'
import { Brand, Order, OrderView } from '@/types'
import { SERVER_BASE_URL, StatusColor } from '@/consts'
import { fallbackCurrency, getCurrencySign } from '@/mixins/UseCurrency'

import { createElement } from 'vuelpers'
import { CartsApi } from '@/api'
import { cartsQuery } from '@/queries'
import { getBasicProductInfos } from '@/mixins/UseProducts'

import OrderViewOrderItem from './OrderViewOrderItem.vue'

export default Vue.extend({
	name: 'OrderView',
	components: {
		OrderViewOrderItem,
	},
	data: () => ({
		printing: false,
		isReordering: false,
		statusColors: StatusColor,
	}),
	computed: {
		...({
			...mapGetters('auth', ['$accessToken', '$isLikeAdmin']),
			...mapGetters('orders', ['$orderView']),
		} as Accessors<{
			$orderView: OrderView
			$accessToken: string
			$isLikeAdmin: boolean
		}>),
		order(): Order | undefined {
			return this.orderView.data
		},
		orderView: {
			get(): OrderView {
				return this.$orderView
			},
			set(v: OrderView) {
				this.$store.commit('orders/SET', {
					orderView: v,
				})
			},
		},
		billingAddress(): string {
			return this.getAddress('Billing')
		},
		shippingAddress(): string {
			return this.getAddress('Shipping')
		},
		currencySign(): string {
			return getCurrencySign(this.order?.vCurrency || fallbackCurrency.code)
		},
		statusColor(): string {
			if (!this.order) return ''
			return this.statusColors[this.order.eStatus]
		},
	},
	methods: {
		numberWithCommas,
		async onReorder() {
			this.isReordering = true

			const [eErr] = await CartsApi.makeEmpty()
			if (eErr) {
				this.isReordering = false
				return this.$toast.error(
					eErr.message || 'Error while emptying cart'
				)
			}

			const [err, res] = await CartsApi.addToCart(
				{
					carts: this.order?.orderItems.map((orderItem) => {
						const brand = orderItem.product.brands?.find((brand) => {
							return brand.vBrand === orderItem.vBrand
						})
						return {
							brand: brand,
							idBrand: brand?.iBrandsId,
							iQuantity: orderItem.iQuantity,
							idProduct: orderItem.product.idProduct,
							product: getBasicProductInfos(
								orderItem.product,
								brand as Brand
							),
						}
					}),
				},
				cartsQuery
			)
			this.isReordering = false

			if (err) {
				return this.$toast.error(
					err.message || 'Error while adding to cart'
				)
			}

			this.$store.commit('carts/SET', { 'carts.data': res.data })
			this.$store.commit('carts/SET', {
				currentOrder: (v: any) => ({
					...v,
					step: 2,
					completedStep: 1,
				}),
			})

			this.orderView.dialog = false
			this.$router.push('/checkout')
		},
		print() {
			return axios({
				url: `${SERVER_BASE_URL}/api/order/invoice/${this.order?.iSalesOrderId}`,
				method: 'GET',
				responseType: 'blob',
				headers: {
					Authorization: `Bearer ${this.$accessToken}`,
				},
			})
				.then((response) => {
					const href = URL.createObjectURL(response.data)
					const attrs = { href, download: 'file.pdf' }
					createElement('a', attrs).click()
				})
				.catch((e) => {
					console.log('printOrder::ERR', e)
				})
		},
		async onClickPrint() {
			this.printing = true
			await this.print()
			this.printing = false
		},
		onPrintOrder() {
			;(this.$refs.html2Pdf as any).generatePdf()
			console.log(this.$refs.html2Pdf)
		},
		getAddress(type: 'Shipping' | 'Billing'): string {
			const order = this.order as any
			if (!order) return ''
			return [
				`v${type}Name`,
				`v${type}CompanyName`,
				`v${type}Address`,
				`v${type}Address2`,
				`v${type}City`,
				`v${type}CountyName`,
				`v${type}CountryName`,
				`v${type}Zipcode`,

				`v${type}Email`,
				`v${type}Phone`,
			]
				.filter((v) => order[v])
				.map((k) => {
					return `<div>${order[k]}</div>${
						k.includes('Zipcode') ? '<br>' : ''
					}`
				})
				.join('')
		},
	},
})
